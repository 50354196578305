/* eslint-disable react/display-name */
import { useEffect } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { useRouter } from "next/router";
import { Loader } from "@/components/loader/Loader";

// routeProtection.js
// withPublic checks if the user is logged in, if they are... it will reroute to '/'.
// (ex:) a logged in user tries to access /login; they will be rerouted to /.
export const withPublic = (WrappedComponent) => {
  return (props) => {
    const router = useRouter();
    const { isAuthenticated, isLoading } = useAuth();

    useEffect(() => {
      if (isAuthenticated) {
        if (router.query.source === "email") {
          router.replace("/view-cart");
        } else {
          router.replace("/");
        }
        return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    if (isAuthenticated || isLoading) {
      return <Loader />; // full-screen loader here
    }
    return <WrappedComponent {...props} />;
  };
};

// withProtected checks if the user is not logged in, if not it will reroute to '/login'.
// (ex:) a logged out user tries to access /account, they will be rerouted to /login.
export const withProtected = (WrappedComponent) => {
  return (props) => {
    const router = useRouter();
    const { isAuthenticated, isLoading } = useAuth();

    useEffect(() => {
      if (!isAuthenticated) {
        router.replace("/login");
        return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    if (!isAuthenticated || isLoading) {
      return <Loader />; // full-screen loader here
    }

    return <WrappedComponent {...props} />;
  };
};
