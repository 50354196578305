import React, { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
// components
import SignIn from "@/components/authentication/SignIn";
import AuthLayout from "@/components/layouts/AuthLayout";
import SEO from "@/components/seo/SEO";

// utils
import Cookies from "universal-cookie";
import { useAuth } from "@/contexts/AuthContext";
import { withPublic } from "@/utils/routeProtection";
import { setToastState } from "@/states/toastBarState";
const LoginScreen = (props) => {
  const router = useRouter();
  const {
    setIsAuthenticated,
    isAuthenticated,
    handleLogin,
    handleOtpResend,
    handleOtpVerification,
    error,
    setError,
    signInData,
    setSignInData,
    openOtpModal,
    setOpenOtpModal,
  } = useAuth();

  return (
    <>
      <SEO title="Login" keywords="" description="login" />
      <AuthLayout title="Login" error={error.show} alertMessage={error.message}>
        <SignIn
          signInData={signInData}
          setSignInData={setSignInData}
          handleLogin={handleLogin}
          error={error}
          setError={setError}
          openOtpModal={openOtpModal}
          setOpenOtpModal={setOpenOtpModal}
          handleOtpVerification={handleOtpVerification}
          handleOtpResend={handleOtpResend}
        />
      </AuthLayout>
    </>
  );
};

LoginScreen.title = "Login";
export default withPublic(LoginScreen);
