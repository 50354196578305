import { useState, useContext, useEffect, useRef, Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

// components
const Toast = dynamic(() => import("@/components/alert/Toast"));
import { ContainedButton } from "@/components/buttons/Buttons";
import { TextInput, Checkbox } from "@/components/inputs/Input";
const OtpDialog = dynamic(() => import("./OtpDialog"));

function SignIn(props) {
  const {
    signInData,
    setSignInData,
    handleLogin,
    handleOtpVerification,
    handleOtpResend,
    error,
    setError,
    openOtpModal,
    setOpenOtpModal,
  } = props;
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [loginValidation, setLoginValidation] = useState(false);
  const [message, setMessage] = useState("");
  const [cartItems, setCartItems] = useState([]);

  //validate created account
  const [otp, setOtp] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      setSignInData({ ...signInData, [e.target.name]: e.target.checked });
    } else if (e.target.value === " ") {
      setSignInData({ ...signInData, [e.target.name]: "" });
    } else {
      setSignInData({ ...signInData, [e.target.name]: e.target.value });
    }
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleValidateOnLogin();
    }
  }

  const handleValidateOnLogin = () => {
    setIsLoading(true);
    if (signInData.primaryEmail !== "" && signInData.password !== "") {
      return handleLogin().then((response) => {
        setIsLoading(false);
        if (response) {
          setTimeout(() => {
            if (router.query.source === "email") {
              router.push("/view-cart");
            } else if (router.query.to === "checkout") {
              router.replace("/checkout");
              //   handleRedirect(platform);
            }
          }, 2000);
        }
      });
    } else {
      setError({
        show: true,
        message: "All fields are required.",
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <TextInput
        label="Email *"
        type="email"
        value={signInData.primaryEmail}
        onChange={onChange}
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
        name="primaryEmail"
        className="mb-3"
      />

      <TextInput
        label="Password *"
        type="password"
        value={signInData.password}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        className="mb-3"
        name="password"
      />

      <div className="justify-between flex flex-col sm:flex-row items-center mb-3">
        <Checkbox
          checked={signInData.keepSignIn}
          onChange={onChange}
          name="keepSignIn"
          label="Keep me signed in"
        />

        <Link passHref href="/forgot-password">
          <a className="no-underline">Forgot Password?</a>
        </Link>
      </div>
      <div className="mb-[50px] mx-auto max-w-[300px]">
        <ContainedButton
          className="w-full rounded-full h-[45px]"
          loading={isLoading}
          onClick={handleValidateOnLogin}
        >
          Sign In
        </ContainedButton>
      </div>
      <div className="text-center">
        <span>Not a member yet? &nbsp;</span>
        {router.query.return === "checkout" ? (
          <Link passHref href="/register?return=checkout">
            <a>Create Account</a>
          </Link>
        ) : (
          <Link passHref href="/register">
            <a>Create Account</a>
          </Link>
        )}
      </div>
      {/* OTP Modal */}
      <OtpDialog
        otpModal={openOtpModal}
        setOtpModal={setOpenOtpModal}
        otp={otp}
        setOtp={setOtp}
        email={signInData.primaryEmail}
        handleOtpVerification={handleOtpVerification}
        handleOtpResend={handleOtpResend}
      />
    </Fragment>
  );
}

export default SignIn;
